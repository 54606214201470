.archived-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  background: none;
}

.archived-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background: none;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.archived-title {
  font-size: 2.5rem;
  color: #69c0ff;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.archived-subtitle {
  color: #91d5ff;
  font-size: 1.1rem;
}

.archived-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.archived-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: white;
}

.archived-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.archived-cover {
  height: 160px;
  background: linear-gradient(135deg, #f0f7ff 0%, #e6f7ff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.archived-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #69c0ff;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.archived-icon {
  font-size: 4rem;
  color: #40a9ff;
}

.archived-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.archived-card:hover .archived-overlay {
  opacity: 1;
}

.archived-card-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1890ff;
  margin-bottom: 1rem;
}

.archived-actions {
  display: flex;
  justify-content: center;
}

.view-archived-btn {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid #91d5ff;
  background: white;
  color: #40a9ff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.view-archived-btn:hover {
  background: #f0f7ff;
  border-color: #40a9ff;
  color: #1890ff;
}

.empty-state {
  grid-column: 1 / -1;
  padding: 4rem;
  text-align: center;
}

.empty-text {
  color: #69c0ff;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .archived-container {
    padding: 1rem;
  }

  .archived-header {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .archived-title {
    font-size: 2rem;
  }

  .archived-grid {
    gap: 1rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .archived-header {
    padding: 1rem;
  }

  .archived-title {
    font-size: 1.75rem;
  }

  .archived-subtitle {
    font-size: 1rem;
  }
}