.page-header {
  background: linear-gradient(135deg, #1a365d 0%, #2563eb 100%);
  padding: 1.5rem 2rem;
  border-radius: 0.75rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: black;
}

.page-title {
  color: white;
  font-size: 1.875rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.page-subtitle {
  color: rgba(255, 255, 255, 0.85);
  margin-top: 0.5rem;
  font-size: 1rem;
}

.ant-table-thead > tr > th {
  background-color: #FAFAFA;
}