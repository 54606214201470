// index.scss

@import '../../styles/_variables.scss'; 
// Custom styles for the header
.SideMenu{
  position: fixed;
  width: 300px;
  left: 0;
  top: 100px;
  // z-index: 10;
  padding: 15px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  .SideMenuVertical{
    width: 100%;
    border-inline-end: none!important;
    li{
      margin-block: 15px;
      display: flex;
      align-items: center;
      span{
        padding-left: 10px;
        color: #717184;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
      &:hover{
        background: linear-gradient(91deg, rgba(4, 158, 247, 0.822) 0.7%, rgba(2, 154, 255, 0.1) 143.6%);
        color: #fff;
        span{
          color: #fff;
        }
      }
      &.ant-menu-item-selected{
        background: linear-gradient(91deg, #94c5f3 0.7%, #063df1 143.6%);
        span{
          color: #fff;
        }
        svg{
          path[fill='#717184']{
            fill: #fff;
          }
        }
      }
    }

    
  }


}

@media (max-width: 768px) {
  .SideMenu{
    display: none;

  }
}