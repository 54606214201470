@import '../../styles/_variables.scss'; 

.PageContent {
  margin-left: 300px;
  width: calc(100% - 300px);
  min-height: 100vh;
  // background-color: #9c2828;
  z-index: 10;
  padding-top: 100px;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
}
