
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
$font-inter: 'Inter', sans-serif;
$font-manrope: 'Manrope', sans-serif;

$primary-color: #DD0744;
$text-color-heading: #1D1E25;
$text-color-paragraph: #717184;

// media breakpoints
$gt-small-screen: 480px;
$gt-medium-screen: 768px;
$gt-large-screen: 1024px;
$gt-extra-large-screen: 1200px;
