.policies-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  padding: 24px;
}

.policies-container {
  margin-top: 24px;
}

.upload-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: white;
}

.upload-card .ant-card-head-title {
  padding: 16px 0;
}

.upload-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.upload-area {
  border: 2px dashed #1890ff;
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  background: #f0f7ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area:hover {
  border-color: #40a9ff;
  background: #e6f4ff;
}

.upload-icon {
  font-size: 32px;
  color: #1890ff;
  margin-bottom: 16px;
}

.file-icon {
  font-size: 40px;
  color: #1890ff;
}

.upload-placeholder p {
  margin: 8px 0;
  color: #666;
}

.upload-hint {
  font-size: 12px;
  color: #999;
}

.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.file-name {
  color: #1890ff;
  font-weight: 500;
  word-break: break-all;
}

.hidden-input {
  display: none;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.policy-input {
  height: 40px;
  border-radius: 6px;
}

.submit-button {
  height: 40px;
  border-radius: 6px;
  background: #1890ff;
  font-weight: 500;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background: #40a9ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

@media (max-width: 768px) {
  .policies-page {
    padding: 16px;
  }
  
  .upload-area {
    padding: 24px 16px;
  }
  
  .form-group {
    gap: 12px;
  }
}

.info-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  height: 100%;
  background: white;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
}

.illustration {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.policy-illustration {
  width: 100%;
  height: auto;
}

.welcome-title {
  color: #1890ff;
  margin-bottom: 0;
}

.info-text {
  color: #666;
  font-size: 16px;
  max-width: 80%;
}

.guidelines-section {
  width: 100%;
  text-align: left;
  padding-top: 20px;
}

.guidelines-icon {
  color: #1890ff;
  margin-right: 8px;
}

.guidelines-list {
  margin-top: 16px;
}

.guidelines-list .ant-list-item {
  padding: 8px 0;
  border-bottom: 1px dashed #e8e8e8;
}

@media (max-width: 768px) {
  .info-section {
    gap: 16px;
  }

  .info-text {
    max-width: 100%;
  }

  .illustration {
    max-width: 200px;
  }
}