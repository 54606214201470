@import './variables.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-inter;
  *:focus {
    outline: none;
  }
}


// custom classes
.custom-primary-button {
  border-radius: 15px;
  background: linear-gradient(91deg, #0008ff  0.7%, #8dafdb 143.6%);
  color: #fff;
}

.custom-primary-button:hover {
  background: linear-gradient(91deg, #375bd17c 0.7%, #476cb1 143.6%)!important;
  color: #fff!important;

}
.custom-secondary-button {
  border-radius: 15px;
  background: linear-gradient(91deg, rgba(221, 7, 68, 0.10) 0.7%, rgba(255, 154, 68, 0.10) 143.6%);
  color: $primary-color;
  &:hover{
    background: linear-gradient(91deg, #0715dd 0.7%, #FF9A44 143.6%)!important;
    color: #fff!important;
  }
}

.competition-card-btn {
  border-radius: 15px;
  border: 1px solid #E9E9E9;
  background: #F6F6F6;
  color: $text-color-heading;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%)!important;
    color: #fff!important;
  }
}
// width 100%
.w-100{
  width: 100%;
}
.br-15{
  border-radius: 15px;
}
.br-7{
  border-radius: 7px;
}
.mb-4{
  margin-bottom: 1.5rem;
}
.mb-5{
  margin-bottom: 1.8rem;
}
.mb-form{
  margin-bottom: 3.75rem!important;
}
.mt-4{
  margin-top: 1.5rem!important;
}
.mx-auto{
  margin-inline: auto!important;
}
.ms-auto{
  margin-left: auto!important;
}
.me-auto{
  margin-right: auto!important;
}
.h-50{
  height: 50px!important;
}
.verticleIcon{
  display: flex!important;
}
.d-flex{
  display: flex!important;
}
.primary-color{
  color: $primary-color!important;
}
.ant-picker-range{
  border-radius: 12px;
border: 1px solid #E9E9E9;
background: linear-gradient(180deg, #FFF 0%, #D7D7D7 100%);
padding: 7.5px 20px;
input{
  color: #717184;
  &::placeholder{
    color: #717184;
  }
}
}

.input-text{
  background-color: #F6F6F6!important;
  border: 1px solid #E9E9E9;
  border-radius: 15px;  
  input{
    background-color: #F6F6F6!important;

  }
}
.p-100{
  padding-top: 100px!important;
}


// Ant Select 
.ant-select{
  height: auto;
  .ant-select-selector{
    border-radius: 12px;
    border: 1px solid rgba(130, 130, 130, 0.20);
    background: linear-gradient(180deg, #FFF 0%, #F6F6F6 100%);
    border: 1px solid #E9E9E9;
    border-radius: 15px; 
    min-width: 140px; 
    min-height: 50px;
    height: auto;
  }
}
// Tabs 

.ant-tabs{
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  .ant-tabs-tab {
    color: $text-color-heading;;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top:0px;
    padding-bottom: 32px;;
    &.ant-tabs-tab-active{
      .ant-tabs-tab-btn{
        color: $text-color-heading;
        text-shadow: none;

      }
    }
  }
  .ant-tabs-content-holder{
    padding-top: 22px;
  }
  .ant-tabs-ink-bar{
    border-radius: 15px 15px 0px 0px;
    height: 4px!important;
    background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
    }
}
// custom radio button

.customradio{
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  .ant-radio-button-wrapper{
    border: none;
    border-right:none;
    font-size: 16px;
    &::before{
      display: none;
    }
    &:hover{
      // background: linear-gradient(91deg, rgba(221, 7, 68, 0.1) 0.7%, rgba(255, 154, 68, 0.1) 143.6%);
      color: #DD0744;   
      border-radius: 15px;
    }
    &.ant-radio-button-wrapper-checked{
      border-radius: 15px;
      background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
      &:hover{
        color: #fff;
      }
    }
  }
}

// status badge
.statusBadge{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 5px 14px;
  
  &.open{
    background: rgba(29, 213, 90, 0.10);
    span{
      width: 8px;
      height: 8px;
      background-color: #1DD55A;
      border-radius: 30px;
      margin-right: 5px;
    }
  }
  &.close{
    background: rgba(222, 12, 68, 0.10);
    span{
      width: 8px;
      height: 8px;
      border-radius: 30px;
      background-color: $primary-color;
      margin-right: 5px;

      
    }
  }
  &.finished{
    // background: #F6F6F6;
    color: #717184;
    span{
      width: 8px;
      height: 8px;
      border-radius: 30px;
      background-color: #717184;;
      margin-right: 5px;
      

      
    }
  }
}
.ant-table{
  .ant-table-thead{
    .ant-table-cell{
      background-color: #000;
      color: #fff;
      &::before{
        display: none;
      }
    }
  }
  
}



// wrapper
.wrapper{
  width: 100%;
  // min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  // authentication pages
  .signin-container{
    min-height: 100%;
    height: 100vh;
    .full-height-row{
      height: 100%;
      .signin-left{
        padding-top: 6rem;
        position: relative!important;
        box-shadow: -20px -20px 124px 0px rgba(0, 0, 0, 0.25) inset;
        color: #fff;
        background: url('../../public/images/authbg.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
        height: 100vh; /* Set the desired height */
        position: relative;
        background-color: #060709; /* Set the color overlay */
        
        .signin-left-content{
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

        h2{
          color: #FFF;
          text-align: center;
          font-family: Inter;
          font-size: 45px;
          font-style: normal;
          font-weight: 700;
          line-height: 70px; /* 155.556% */
          margin-bottom: 1.8rem;
        }
        p{
          color: #FFF;
          font-family: $font-manrope;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px; /* 135% */
          letter-spacing: 1px;
          margin-bottom: 3rem;
        }
        .slider-img{
          margin-bottom: 2.4rem;
        }
        .leftimage{
          flex-grow: 1;
          position: relative;
          .bottom-img{

            margin-left: 100px; /* Left margin of 100px */
            max-width: calc(100% - 100px); /* Maximum width, considering the left margin */
            height: 100%; /* Take the full height of the parent */
            
          }
        }

        }
       
        }
        .signin-right{
          display: flex;
          flex-direction: column;
          padding: 40px 50px ;
          .signinLogo{
            max-width: 290px;
          }
          .signin-right-internal{
            margin-block: auto;
           
            
            .heading{
              color: $text-color-heading;
              font-feature-settings: 'clig' off, 'liga' off;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-bottom: 1.25rem;
              span{
                color: $primary-color;
              }
            }
            .subtitle{
              color: $text-color-paragraph;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 2.5rem;
            }
            button{
              height: 55px;
            }
            input[type="email"], input[type="text"], input[type="password"] {
              height: 55px;
              color: $text-color-paragraph;
            }
            .ant-form-item-control-input{
              margin-bottom: 1.5rem;
             
              .ant-input-affix-wrapper{
                background-color: #F6F6F6!important;
                border: 1px solid #E9E9E9;
                border-radius: 15px;                
              }
              .input-text{
                // background-color: #F6F6F6!important;
                border: 1px solid #E9E9E9;
                border-radius: 15px;  
              }
              
              input{
                background-color: #F6F6F6;
              }

            }
            label{
              font-size: 18px;
              color: $text-color-heading;
            }
          
          }
          .padding-md{
            padding-inline: 120px;
          }
          .padding-sm{
            padding-inline: 60px;
          }
          .social-icons{
            margin: auto;
          padding: 0%;
          display: flex;
          gap: 1.2rem;
            li{
              list-style: none;
              display: flex;
            }            
          }
          .signin-right-bottom{
            margin-top: auto;
            p{
              text-align: center;
              color: $text-color-paragraph;
              font-size: 18px;
              span{
                color:$primary-color;
              }

            }
          }

        }
        .signup-from{
          .ant-form-item{
            margin-bottom: 0%;
          }
          .ant-select-selector{
            background-color: #F6F6F6!important;
            border: 1px solid #E9E9E9;
            border-radius: 15px;  
            height: 55px;
           
          }
          .ant-select-arrow{
            top: 100%;
          }
          .ant-checkbox-wrapper{
            .ant-checkbox{
              align-self: auto;
            }
            .link{
              color: $primary-color;
              padding-left: 8px;
              a{
                color: inherit;
              }
            }
          }
         

        }
    }
   
  }
  .New-Challenge-page{
    padding: 30px 40px;
    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .customradio{
        padding: 10px;
        background-color: #fff;
        border-radius: 15px;
        .ant-radio-button-wrapper{
          border: none;
          border-right:none;
          font-size: 16px;
          vertical-align: middle;
          height: 50px;
          padding-block: 10px;
          
          &::before{
            display: none;
          }
          &:hover{
            // background: linear-gradient(91deg, rgba(221, 7, 68, 0.1) 0.7%, rgba(255, 154, 68, 0.1) 143.6%);
            color: #DD0744;   
            border-radius: 15px;
          }
          &.ant-radio-button-wrapper-checked{
            border-radius: 15px;
            background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
            height: 50px;
            padding-block: 10px;
            vertical-align: middle;
            &:hover{
              color: #fff;
            }
          }
        }
      }

    }
    .banner{
      background: url('../../public/images/challengeBG.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
      border-radius: 16px;
      
     
      .challangeText{
        padding: 30px;
       
        .box{
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.10);
          padding: 10px 30px;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p{
            color: #FFF;
            font-size: 18px;
            opacity: 0.7;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.889% */
          }
          h1{
            color: #FFF;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

        }
        .list{
          color: #fff;

         
          p{
            color: #FFF;
            font-family: $font-manrope;;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 10px;
          }
          .ant-checkbox-wrapper{
            span{
              font-size: 14px;
              align-self: flex-start;
              margin-top: 4px;
              color: #fff;
            }
          }
          button{
            margin-top: 40px;
            border-radius: 15px!important;
            padding: 7.5px 90px;
            display: flex;
          align-items: center;
          justify-content: center;
          }

        }
      }
    }
    .account{
      border-radius: 13px;
      .accountheader{
        padding: 20px 25px 0px 25px;
        display: flex;
        h4{
          margin-right: auto;
          color: #1D1E25;
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: normal
        }
        .accountHeader-left{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
          .input-text{
            padding: 9px 5px;
            max-width: 300px;
          }
          .ant-select{
            height: auto;
            .ant-select-selector{
              border-radius: 12px;
              border: 1px solid rgba(130, 130, 130, 0.20);
              background: linear-gradient(180deg, #FFF 0%, #F6F6F6 100%);
              border: 1px solid #E9E9E9;
              border-radius: 15px; 
              min-width: 140px; 
              min-height: 50px;
              height: auto;
            }

          }
          
         
          button{
            min-height: 50px;
          }
        }
      }
      .package-card-container{
        padding: 20px;
        padding-top:0px;
        // height: calc(100vh - 270px);
        overflow-y: scroll;
      }
    }
  }
  .Dashboard-page{
    padding: 30px 40px;
    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .customradio{
        padding: 10px;
        background-color: #fff;
        border-radius: 15px;
        .ant-radio-button-wrapper{
          border: none;
          border-right:none;
          font-size: 16px;
          &::before{
            display: none;
          }
          &:hover{
            // background: linear-gradient(91deg, rgba(221, 7, 68, 0.1) 0.7%, rgba(255, 154, 68, 0.1) 143.6%);
            color: #DD0744;   
            border-radius: 15px;
          }
          &.ant-radio-button-wrapper-checked{
            border-radius: 15px;
            background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
            &:hover{
              color: #fff;
            }
          }
        }
      }

    }
    .banner{
      background: url('../../public/images/challengeBG.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
      border-radius: 16px;
      
      .logobanner{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px 15px 0px 0px;
        background: #060709;
        padding:30px 60px ;

      }
      .challangeText{
        padding: 30px;
        h2{
          background: linear-gradient(104deg, #DD0744 2.29%, #FFE45A 142.04%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          text-align: center;
        }
        .box{
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.10);
          padding: 10px 30px;
          margin-block: 1.5rem;
          p{
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-family: $font-manrope;
          }

        }
        .list{
          color: #fff;
          h5{
            color: #FFF;
            font-family: $font-manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 20px;
          }
          ul{
            margin-bottom: 20px;
            list-style: none;
            li{
              .check-icon {
               
                border-radius: 50%;
               
                
                margin-right: 10px;
              }
              display: flex;
              color: #FFF;
              font-family: $font-manrope;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;
              margin-bottom: 15px;

            }
          }
          p{
            color: #FFF;
            text-align: center;
            font-family: $font-manrope;;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 3.5rem;

          }
          button{
            border-radius: 15px!important;
            padding: 7.5px 90px;
            display: flex;
    align-items: center;
    justify-content: center;
          }

        }
      }
    }
    .account{
      background-color: #fff;
      border-radius: 13px;
      .accountheader{
        padding: 20px 25px 0px 25px;
        display: flex;
        h4{
          margin-right: auto;
          color: #1D1E25;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal
        }
        .accountHeader-left{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
          .input-text{
            padding: 9px 5px;
            max-width: 300px;
          }
          .ant-select{
            height: auto;
            .ant-select-selector{
              border-radius: 12px;
              border: 1px solid rgba(130, 130, 130, 0.20);
              background: linear-gradient(180deg, #FFF 0%, #F6F6F6 100%);
              border: 1px solid #E9E9E9;
              border-radius: 15px; 
              min-width: 140px; 
              min-height: 50px;
              height: auto;
              
            }

          }
          
         
          button{
            min-height: 50px;
          }
        }
      }
      .package-card-container{
        padding: 20px;
        padding-top:0px;
        // height: calc(100vh - 270px);
      }
    }
  }
  .Matrix-page{
    padding: 30px 40px;
    z-index: 1000;
    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .left-section{
        display: flex;
        gap: 20px;
      }
    }
    .icon-card-container{
      display: flex;
      gap: 30px;
    }
    .trading-view-section{
      margin-block:30px;
      .pageHeader{
        margin-bottom: 20px;
      }
      .charts-container{
        .grothsection{
          display: flex;
          justify-content: space-between;
          padding-block: 18px;
          p{
            color: #717184;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
          }
          h6{
            color: #1D1E25;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
        }
      }
    }
    .table-container{
      background-color: #fff;
      padding-block:20px ;
      border-radius: 15px;
      .pageHeader{
        padding-inline: 20px;
        h1{
          color: #1D1E25;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 20px;
        }
      }
    }
   

  }
  .TradingPlatform-page{

    height: calc(100vh - 96px);
    background: url('../../public/images/tradingbg.png'), lightgray 50% / cover no-repeat;
    .page-container{
      width: 100%;
      height: 100%;
      background: rgba(246, 246, 246, 0.90);
      backdrop-filter: blur(2.5px);
      .header{
        display: flex;
        padding-top: 30px;
        .customradio{
          margin: auto;
          padding: 10px;
          background-color: #fff;
          border-radius: 15px;
          .ant-radio-button-wrapper{
            border: none;
            border-right:none;
            font-size: 16px;
            &::before{
              display: none;
            }
            &:hover{
              // background: linear-gradient(91deg, rgba(221, 7, 68, 0.1) 0.7%, rgba(255, 154, 68, 0.1) 143.6%);
              color: #DD0744;   
              border-radius: 15px;
            }
            &.ant-radio-button-wrapper-checked{
              border-radius: 15px;
              background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
              &:hover{
                color: #fff;
              }
            }
          }
        }
      }
      .account-container{
        background-color: #fff;
        max-width: 390px;
        margin: auto;
        margin-top: 160px;
        border-radius: 15px;
        h6{
          padding: 20px 20px 0px 20px;
          color: #1D1E25;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .login-container{
          padding: 20px!important;
          padding-top: 0px!important;
          label{
            color: #1D1E25;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .custom-label{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
              color: #1D1E25;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              &.forgot-password{
                color: #1D1E25;
                text-align: right;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                a{
                  color: $primary-color!important;
                }
              }
            }
          }
          .ant-form-item-control-input{
            .ant-input-affix-wrapper{
              background-color: #F6F6F6!important;
              border: 1px solid #E9E9E9;
              border-radius: 15px;                
            }
            .input-text{
              background-color: #F6F6F6!important;
              border: 1px solid #E9E9E9;
              border-radius: 15px;  
            }
            input{
              background-color: #F6F6F6;
            }
          }
        }
       
      }
    }

  }
  .Payout-page{
    padding: 30px 40px;
    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .left-section{
        display: flex;
        gap: 20px;
      }
    }
    .right-container{
      .Request-card{
        background: url('../../public/images/challengeBG.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
        border-radius: 16px;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 30px;
        h1{
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 33px; /* 117.857% */
          margin-bottom: 20px;
        }
        p{
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px; /* 166.667% */
          margin-bottom: 30px;
          span{
            color: $primary-color;
          }
        }
        button{
          min-height: 50px;
          padding: 7.5px 50px;
        }
      }
      .payout-method-card{
        background:#fff;
        padding:20px;
        border-radius: 16px;
        margin-bottom: 30px;
        h1{
          color: #1D1E25;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 10px;
        }
        p{
          color: #717184;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          margin-bottom: 15px;
        }
        ul{
          list-style: none;
          padding: 0px;
          li{

          }
        }


      }
      .warning{
        border-radius: 15px;
        border: 1px solid #DE0C44;
        background: #FFFBFC;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
          color: #717184;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px; /* 178.571% */
        }
        span{
          padding-right: 10px;
        }
      }
  }
}
.Certificate-page{
  padding: 30px 40px;
  .banner{
    margin-bottom: 30px;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .pageHeader{
      h1{
        margin-bottom: 16px;
      }
    }
    .left-section{
      display: flex;
      gap: 20px;
    }
    .right-section{
      display: flex;
      align-items: end;
      justify-content: flex-end;
    }
  }
}
.Competition-page{
  padding: 30px 40px;
  .banner{
    margin-bottom: 30px;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .pageHeader{
      h1{
        margin-bottom: 16px;
      }
    }
    .left-section{
      display: flex;
      gap: 20px;
    }
    .right-section{
      display: flex;
      align-items: end;
      justify-content: flex-end;
    }
  }

}
}