.policy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.policy-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background: none;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.policy-title {
  font-size: 2.5rem;
  color: #1890ff;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.policy-subtitle {
  color: #69c0ff;
  font-size: 1.1rem;
}

.policy-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.policy-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.policy-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.card-cover {
  height: 160px;
  background: linear-gradient(135deg, #e6f7ff 0%, #bae7ff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.file-icon {
  font-size: 4rem;
  color: #40a9ff;
}

.policy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.policy-card:hover .policy-overlay {
  opacity: 1;
}

.view-icon {
  font-size: 2rem;
  color: #1890ff;
}

.policy-card-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #262626;
  margin-bottom: 1rem;
}

.card-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.view-btn, .archive-btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  flex: 1;
}

.view-btn {
  background: #1890ff;
  color: white;
}

.view-btn:hover {
  background: #40a9ff;
}

.archive-btn {
  background: #fff;
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
}

.archive-btn:hover {
  background: #fff1f0;
}

.loading-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  padding: 4rem;
}

.empty-state {
  grid-column: 1 / -1;
  padding: 4rem;
  text-align: center;
}

@media (max-width: 768px) {
  .policy-container {
    padding: 1rem;
  }

  .policy-header {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .policy-title {
    font-size: 2rem;
  }

  .policy-grid {
    gap: 1rem;
    padding: 0.5rem;
  }

  .card-actions {
    flex-direction: column;
  }
}