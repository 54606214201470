.leaves-table .ant-table-thead > tr > th {
  background-color: red;
}

.table-container {
  // ...existing styles...
}

.table-container table {
  // ...styles for table...
}

.table-container th,
.table-container td {
  // ...styles for table headers and cells...
}