// index.scss

@import '../../styles/_variables.scss'; 
// Custom styles for the header
.ant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 21px 50px;
  z-index: 50;

  color: #000;
  background-color: #5ea2e2; // Light blue background
  height: auto;
  line-height: normal;
  border-bottom: 1px solid #E9E9E9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); // Add shadow
  border-radius: 8px; // Rounded corners


  // Logo styling
  .logo {
    font-weight: bold;
    margin-right: 20px;
    img{  
        max-height: 50px;      
    }
    
  }
  button{
    min-width: 153px;
  }

  

  // User profile dropdown styling
  .user-profile {
    display: flex;
    margin-left: 10px;
    align-items: center;
    color: #fff;

    @media (max-width: 640px) { // Assuming sm breakpoint is 640px
      // display: none;
      margin-left: 130px;
    }

    // Avatar styling
    .profileImage{
        max-height: 50px;
        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-inline: 12px;
            margin-right: 10px;
            border-radius: 50%; // Rounded avatar
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow to avatar
          }
    }
    
    .nameDropdown{
        display: flex;
        flex-direction: column;
        .name{
            font-weight: bold;
            line-height: normal;
            margin-bottom: 5px;
            margin-right: 8px;
        }
        .profiledropdown{
            color: #fff;
            font-size: 14px;
            max-height: 15px;
            cursor: pointer;
            .ant-space{
                max-height: 15px;
            }
        }
        // Dropdown menu styling
        .ant-dropdown-link {
          color: $text-color-paragraph;
          margin-left: 8px;
        }
    }
   
  }
}

.logo-img {
  width: 150px;
}

.profiledropdown:hover {
  color: #1890ff; // Lighter blue on hover
}

a.profiledropdown {
  text-decoration: none;
}

.notification-detail-modal {
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    padding: 16px 24px;
  }
  
  .ant-modal-body {
    padding: 0;
  }
}

.ant-header {
  position: relative;
  z-index: 1000;
}


// Responsive styles
@media (max-width: 768px) {
  .logo {
    display: none; // Hide logo on smaller screens
  }

  .ant-header {
    padding: 0 10px;
  }

  .user-profile {
    // Adjust styles for smaller screens
  }
}
